<template>
  <div class="column designThinking gap" ref="designThinkingRef">
    <p>How can we help you?</p>

    <div
      v-if="selectedPhases.length === 0"
      class="selectedPhases row smallGap alignCentreRow"
    >
      <small class="instructionLabel smallText gray">Click to select</small>
      <div class="chips-container">
        <div class="chip transparent">Placeholder</div>
      </div>
    </div>
    <div v-else class="selectedPhases row smallGap alignCentreRow">
      <small class="instructionLabel smallText gray">Phases involved</small>
      <div class="chips-container">
        <div class="chip" v-for="phase in selectedPhases" :key="phase">
          {{ phase }}
        </div>
      </div>
    </div>

    <div :class="[isMobile ? 'column' : 'row', 'phases-wrapper']">
      <!-- Start Image -->
      <img :src="startImageSrc" alt="" class="start-end-image" />

      <div class="phases-container">
        <div
          v-for="(phase, index) in phases"
          :key="phase"
          :class="[
            'phase',
            phase.toLowerCase(),
            { selected: selectedPhases.includes(phase) },
          ]"
          @click="togglePhase(phase)"
        >
          <img :src="phaseImages[phase]" alt="" class="phase-image" />
          <div v-if="!selectedPhases.includes(phase)" class="phase-description">
            <p class="lightGray">{{ phaseDescriptions[phase] }}</p>
          </div>
        </div>
      </div>

      <!-- End Image -->
      <img :src="endImageSrc" alt="" class="start-end-image" />
    </div>
    <!-- Fixed "Get in touch" button, visible only when component is in view -->
    <button class="get-in-touch-button" @click="submitContact" v-if="isInView">
      Get in touch
    </button>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";

export default {
  name: "DesignThinking",
  setup() {
    const store = useStore();

    const phases = ["Discovery", "Definition", "Development", "Delivery"];

    const phaseDescriptions = {
      Discovery: "Understanding the problem",
      Definition: "Defining the problem",
      Development: "Developing possible solutions",
      Delivery: "Delivering the solution",
    };

    const isMobile = ref(window.innerWidth <= 768);

    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    onMounted(() => {
      window.addEventListener("resize", updateIsMobile);
    });

    const phaseImages = computed(() => {
      if (isMobile.value) {
        return {
          Discovery: require("@/assets/DesignDiagram/design_thinking_jam_1.png"),
          Definition: require("@/assets/DesignDiagram/design_thinking_jam_2.png"),
          Development: require("@/assets/DesignDiagram/design_thinking_jam_3.png"),
          Delivery: require("@/assets/DesignDiagram/design_thinking_jam_4.png"),
        };
      } else {
        return {
          Discovery: require("@/assets/DesignDiagram/design_thinking_jam_hor_1.png"),
          Definition: require("@/assets/DesignDiagram/design_thinking_jam_hor_2.png"),
          Development: require("@/assets/DesignDiagram/design_thinking_jam_hor_3.png"),
          Delivery: require("@/assets/DesignDiagram/design_thinking_jam_hor_4.png"),
        };
      }
    });

    const startImageSrc = computed(() => {
      if (isMobile.value) {
        return require("@/assets/DesignDiagram/design_thinking_jam_0.png");
      } else {
        return require("@/assets/DesignDiagram/design_thinking_jam_hor_0.png");
      }
    });

    const endImageSrc = computed(() => {
      if (isMobile.value) {
        return require("@/assets/DesignDiagram/design_thinking_jam_5.png");
      } else {
        return require("@/assets/DesignDiagram/design_thinking_jam_hor_5.png");
      }
    });

    const togglePhase = (phase) => {
      if (store.state.clickedPhases.includes(phase)) {
        store.commit("removePhase", phase);
      } else {
        store.commit("addPhase", phase);
      }
    };

    const selectedPhases = computed(() => store.state.clickedPhases);

    const submitContact = () => {
      // Scroll to the contact form
      const contactForm = document.getElementById("contact-form");
      if (contactForm) {
        contactForm.scrollIntoView({ behavior: "smooth" });
      }
    };

    // Detect when the component is in view
    const designThinkingRef = ref(null);
    const isInView = ref(false);
    let observer = null;

    onMounted(() => {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            isInView.value = entry.isIntersecting;
          });
        },
        {
          root: null,
          threshold: 0.5, // 50% of the component must be in view
          rootMargin: "0px", // Adjust the component's entry/exit point
        }
      );

      if (designThinkingRef.value) {
        observer.observe(designThinkingRef.value);
      }
    });

    onBeforeUnmount(() => {
      if (observer && designThinkingRef.value) {
        observer.unobserve(designThinkingRef.value);
      }
    });

    return {
      phases,
      phaseDescriptions,
      phaseImages,
      startImageSrc,
      endImageSrc,
      isMobile,
      togglePhase,
      selectedPhases,
      submitContact,
      designThinkingRef,
      isInView,
    };
  },
};
</script>

<style scoped>
.designThinking {
  scroll-snap-align: start;
  margin: 0;
  padding: var(--space);
  position: relative;
}

.phases-wrapper {
  margin-top: var(--space);
  width: 100%;
}

.start-end-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.phases-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  width: 100%;
  max-width: 800px;
  overflow: visible;
}

.phase {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--bg-main-dark);
  border: var(--button-border-default);
  cursor: pointer;
  transition: all var(--fastSpeed) ease;
  box-sizing: border-box;
  height: 130px; /* Fixed height on mobile */
  border-radius: var(--border-radius-pill);
}

.phase-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: -1;
}

.phase.selected {
  background-color: transparent;
  border: none; /* Remove border when selected */
  border-radius: 0;
}

.phase-description {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 10px;
  text-align: center;
  color: var(--text-white);
  font-size: var(--regularFontSize);
}

.smallText.gray {
  color: var(--bg-dark-tint-3);
}

.selectedPhases {
  position: relative;
  top: var(--space);
}

.instructionLabel {
  position: absolute;
  transform: translateY(calc(var(--doubleLargeSpace) * -1.25));
  margin-top: var(--space);
}

.lightGray {
  color: var(--bg-dark-tint-5);
}

/* Position the "Get in touch" button fixed at the bottom left */
.get-in-touch-button {
  position: fixed;
  bottom: var(--space);
  left: var(--space);
  z-index: 1001; /* Ensure the button is above other elements */
}

@media screen and (min-width: 768px) {
  .phases-wrapper {
    display: flex;
    flex-direction: row;
    height: 60vh;
    align-items: stretch;
    justify-content: space-around;
  }

  .start-end-image {
    flex: 0 0 15%;
    height: 100%;
    object-fit: contain;
  }

  .phases-container {
    flex-direction: row;
    flex: 1;
    align-items: stretch;
  }

  .phase {
    width: auto;
    height: 100%;
    flex: 1;
  }

  .phase-image {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .phase {
    height: 120px;
  }
}
</style>
