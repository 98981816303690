export default {
  projects: [
    {
      title: "webapp",
      description: "developed in house",
      backgroundColor: "#2d2d2d",
      steps: [
        {
          backgroundColor: "#2d2d2d",
          backgroundImage: {
            srcVertical: require("@/assets/step_images/unit/invite-preview_vert.png"),
            srcHorizontal: require("@/assets/step_images/unit/invite-preview.png"),
          },
          textColor: "#d3d3d3",
          images: [
            {
              type: "image",
              src: require("@/assets/step_images/unit/iphone15_unit.png"),
              parallaxSpeed: 4,
              // New scale properties
              scaleDesktop: 0.5,
              scaleMobile: 1.0,
              rotation: 5,
              positionAdjustDesktop: { x: "75%", y: "65%" },
              positionAdjustMobile: { x: "75%", y: "65%" },
              zIndex: 1,
            },
          ],
        },
        {
          textColor: "#d3d3d3",
          images: [
            {
              type: "video",
              src: require("@/assets/step_images/unit/videos/RegisterAndCreateOrg_vertical.mp4"),
              parallaxSpeed: 1,
              // For these, we can keep a single scale or add separate if needed
              scaleDesktop: 0.42,
              scaleMobile: 1.0,
              rotation: 0,
              positionAdjustDesktop: { x: "50%", y: "50%" },
              positionAdjustMobile: { x: "50%", y: "50%" },
              zIndex: 1,
            },
          ],
        },
        {
          backgroundColor: "#2d2d2d",
          textColor: "#d3d3d3",
          images: [
            {
              type: "video",
              src: require("@/assets/step_images/unit/videos/Add_Product_vertical.mp4"),
              parallaxSpeed: 1,
              scaleDesktop: 0.42,
              scaleMobile: 1.0,
              rotation: 0,
              positionAdjustDesktop: { x: "50%", y: "50%" },
              positionAdjustMobile: { x: "50%", y: "50%" },
              zIndex: 1,
            },
          ],
        },
        {
          backgroundColor: "#2d2d2d",
          textColor: "#d3d3d3",
          images: [
            {
              type: "video",
              src: require("@/assets/step_images/unit/videos/Add_Role_vertical_1.mp4"),
              parallaxSpeed: 1,
              scaleDesktop: 0.42,
              scaleMobile: 1.0,
              rotation: 0,
              positionAdjustDesktop: { x: "50%", y: "50%" },
              positionAdjustMobile: { x: "50%", y: "50%" },
              zIndex: 1,
            },
          ],
        },
        {
          textBlocks: [
            {
              text: "A webapp that allows users to create and manage their own currency.",
              fontSize: 20,
              parallaxSpeed: 3,
              rotation: 0,
              scale: 1,
              positionAdjustDesktop: { x: "70%", y: "65%" },
              positionAdjustMobile: { x: "50%", y: "50%" },
              zIndex: 2,
            },
          ],
        },
        // More steps if needed...
      ],
    },
    // More projects if needed...
  ],
  partners: [
    {
      name: "João Marrucho",
      photo: require("@/assets/partners/joaoMarrucho/joaoMarrucho.jpg"),
      expertise: [
        "Service Design",
        "UX",
        "Developer",
        "Strategy",
        "Organisational Design",
      ],
      logos: [
        require("@/assets/logos/barclays.png"),
        require("@/assets/logos/camden.png"),
        require("@/assets/logos/ey.png"),
        require("@/assets/logos/UAL_csm.png"),
        require("@/assets/logos/fcg.png"),
      ],
      bio: "Service designer and developer with two decades of multi-disciplinary experience helping shape new visions.",
      workImages: [
        require("@/assets/partners/joaoMarrucho/unit.jpg"),
        require("@/assets/partners/joaoMarrucho/footfall.jpg"),
        require("@/assets/partners/joaoMarrucho/lavaria1.jpg"),
        require("@/assets/partners/joaoMarrucho/cmf_workshop.png"),

        require("@/assets/partners/joaoMarrucho/workshop.jpg"),
      ],
    },
    {
      name: "Valeska Mangel",
      photo: require("@/assets/partners/valeskaMangel/valeska.jpg"),
      expertise: ["Service Design", "User Research", "Futures thinking"],
      logos: [
        require("@/assets/logos/wallpaper.png"),
        require("@/assets/logos/ual.png"),
        require("@/assets/logos/epowar.png"),
        require("@/assets/logos/zukunftsstiftung.png"),
      ],
      bio: "Researcher, designer, and writer specialised in service design and future research.",
      workImages: [
        require("@/assets/partners/valeskaMangel/work2.jpg"),
        require("@/assets/partners/valeskaMangel/work3.jpg"),
        require("@/assets/partners/valeskaMangel/work1.jpg"),
      ],
    },
    {
      name: "Miguel M. Pereira",
      photo: require("@/assets/partners/miguelMaria/miguel_maria.jpg"), // Replace with actual photo URL if available
      expertise: [
        "European Politics",
        "Elite Behavior",
        "Policy-Making",
        "Quantitative Methods",
      ],
      logos: [
        require("@/assets/logos/lse.png"),
        require("@/assets/logos/EU_JRC.png"),
        require("@/assets/logos/oxford_net0.png"),
        require("@/assets/logos/welcoming_america.png"),
        require("@/assets/logos/ffms.png"),
        require("@/assets/logos/elect-her.png"),
      ],
      bio: "Political science researcher focused on elite behavior, quantitative methods and experimental research approaches.",
      workImages: [
        require("@/assets/partners/miguelMaria/workshop.jpg"),
        require("@/assets/partners/miguelMaria/interview.jpg"),
      ],
    },

    {
      name: "Spencer Cappiello",
      photo: require("@/assets/partners/spencerCappiello/spencerCappiello.jpg"), // Replace with actual path
      expertise: ["Web3", "Fullstack Development", "VR/AR Prototyping"],
      logos: [
        // require("@/assets/logos/polaroid.png"),
        require("@/assets/logos/coinbase.png"),
        require("@/assets/logos/algorand.png"),
        require("@/assets/logos/nyu.png"),
        require("@/assets/logos/guggenheim.png"),
        require("@/assets/logos/WMG.png"),
        require("@/assets/logos/artBasel.png"),
      ],
      bio: "Fullstack developer with expertise in Web3, hardware engineering and VR/AR prototyping.",
      workImages: [
        require("@/assets/partners/spencerCappiello/refraction.jpg"),
        require("@/assets/partners/spencerCappiello/IIIpoints.jpg"),
        require("@/assets/partners/spencerCappiello/rundmc.jpg"),
      ],
    },

    {
      name: "Rita Garizo",
      photo: require("@/assets/partners/ritaGarizo/rita.jpg"),
      expertise: ["Event production", "Community building", "Product design"],
      logos: [
        require("@/assets/logos/kate.png"),
        require("@/assets/logos/hushpuppies.png"),
        require("@/assets/logos/redbull.png"),
        require("@/assets/logos/surge.png"),
        require("@/assets/logos/vicaima.png"),
      ],
      bio: "A powerhouse event producer and accomplished product designer, renowned for her practicality and relentless effort to bring ideas to life.",
      workImages: [
        // require("@/assets/partners/ritaGarizo/work3.jpg"),
        require("@/assets/partners/ritaGarizo/rbma.jpg"),
        require("@/assets/partners/ritaGarizo/work5.jpg"),
        // require("@/assets/partners/ritaGarizo/work2.jpg"),
        require("@/assets/partners/ritaGarizo/work0.jpg"),
        require("@/assets/partners/ritaGarizo/vicaima_1.jpg"),
        // require("@/assets/partners/ritaGarizo/sofa.jpg"),
        // require("@/assets/partners/ritaGarizo/work1.jpg"),
      ],
    },
    {
      name: "Duarte Amorim",
      photo: require("@/assets/partners/duarteAmorim/duarteAmorim.jpeg"),
      expertise: ["Immersive Media", "Programmer", "Laser/light instalations"],
      logos: [
        require("@/assets/logos/ade.png"),
        require("@/assets/logos/unknown_electronics.png"),
        require("@/assets/logos/serralves.png"),
      ],
      bio: "A designer and creative technologist specialised in large scale immersive media projects.",
      videoLinks: [
        require("@/assets/partners/duarteAmorim/Range_of_uncertainty.mp4"),
      ],
    },
    {
      name: "Nelson Crespo",
      photo: require("@/assets/partners/nelsonCrespo/nelsonCrespo.jpg"),
      expertise: [
        "Team management",
        "Digital media education",
        "Organisational design",
        "Art conservation",
      ],
      logos: [
        require("@/assets/logos/tate.png"),
        require("@/assets/logos/rca.png"),
        require("@/assets/logos/UAL_csm.png"),
        require("@/assets/logos/LVMH.png"),
        require("@/assets/logos/google-arts-culture-logo.png"),
        require("@/assets/logos/misk.png"),
      ],
      bio: "Technology enabler who has lead innovation and transformation projects spanning academia, media, tech, and cultural sectors.",
      workImages: [
        require("@/assets/partners/nelsonCrespo/workshop.jpg"),
        require("@/assets/partners/nelsonCrespo/tate.webp"),
        // require("@/assets/partners/nelsonCrespo/rca.jpg"),
        require("@/assets/partners/nelsonCrespo/csm.jpg"),
      ],
    },
    {
      name: "Samuele Albani",
      photo: require("@/assets/partners/samueleAlbani/samueleAlbani.jpeg"),
      expertise: [
        "Human-Computer interaction",
        "Robotics",
        "Machine Learning",
        "Internet of Things",
      ],
      logos: [
        require("@/assets/logos/rca.png"),
        require("@/assets/logos/vatican_museums.png"),
        require("@/assets/logos/palazzo_reale.png"),
        require("@/assets/logos/tate.png"),
      ],
      bio: "Artist, designer, and fullstack developer with a focus on interactions between computers and humans.",
      workImages: [
        require("@/assets/partners/samueleAlbani/OcularChoreography_1.png"),
        require("@/assets/partners/samueleAlbani/con_touch.png"),
      ],
      videoLinks: [
        "https://vimeo.com/905196414?from=outro-embed",
        "https://vimeo.com/780754198",
      ],
    },

    {
      name: "Arnaud Meneroud",
      photo: require("@/assets/partners/arnaudMeneroud/arnaud.jpeg"),
      expertise: [
        "Product Manager",
        "Developer",
        "Strategic planning",
        "XR",
        "AI",
        "UX",
        "UI",
      ],
      logos: [
        require("@/assets/logos/hermes.png"),
        require("@/assets/logos/dior.png"),
        require("@/assets/logos/cent_quatre.png"),
      ],
      bio: "Strategist, Product manager and XR engineer who specialises in creating XR-enhanced products, translating concepts into impactful market-ready solutions.",
      workImages: [
        require("@/assets/partners/arnaudMeneroud/work3.png"),
        require("@/assets/partners/arnaudMeneroud/work4.gif"),
      ],
      videoLinks: ["https://vimeo.com/885640906"],
    },
    // {
    //   name: "Rían Stephens",
    //   photo: require("@/assets/partners/rianStephens/rianStephens.jpeg"),
    //   expertise: ["VR", "Interactive Storytelling", "Unreal Engine"],
    //   logos: [
    //     require("@/assets/logos/outernetLondon.png"),
    //     require("@/assets/logos/rca.png"),
    //     require("@/assets/logos/MTU.png"),
    //     require("@/assets/logos/university_of_limerick.png"),
    //   ],
    //   bio: "Computer engineer and virtual reality developer whose portfolio highlights immersive experiences and innovative uses of VR for interactive storytelling.",
    //   workImages: [
    //     require("@/assets/partners/rianStephens/outernet.jpeg"),
    //     require("@/assets/partners/rianStephens/outernet_2.jpeg"),
    //   ],
    //   videoLinks: [
    //     "https://www.youtube.com/watch?app=desktop&v=0PlC6h5mcj4",
    //     "https://www.youtube.com/watch?v=DK1b2jhqM4I",
    //   ],
    // },
    // {
    //   name: "Nuno Miranda",
    //   photo: require("@/assets/partners/nunoMiranda/nunoMiranda.jpeg"),
    //   expertise: ["Branding", "Creative Strategy", "Communications"],
    //   logos: [
    //     require("@/assets/logos/redbull.png"),
    //     require("@/assets/logos/vice.png"),
    //     require("@/assets/logos/amorim_cork_composites.png"),
    //   ],
    //   bio: "Nuno Miranda, with over 20 years of experience, is a creative director known for his dynamic branding and communication work.",
    //   workImages: [
    //     require("@/assets/partners/nunoMiranda/tremor2024_1.jpg"),
    //     require("@/assets/partners/nunoMiranda/tremor2024_2.jpg"),
    //     require("@/assets/partners/nunoMiranda/tremor2024_3.jpg"),
    //   ],
    //   videoLinks: ["https://www.youtube.com/watch?v=k1wdPr3Jo98"],
    // },
  ],
  contactCTA: {
    message: "Say hi!",
  },
};
