// src/utils/shaderMaterials.js
import * as THREE from "three";

/**
 * Creates a moving blur shader material for trails.
 */
export function createBlurMaterial(color = 0xeeeeee) {
  return new THREE.ShaderMaterial({
    uniforms: {
      color: { value: new THREE.Color(color) },
      time: { value: 0.0 },
      opacity: { value: 0.8 }, // Added opacity uniform
    },
    vertexShader: `
      uniform float time;
      varying vec2 vUv;
      
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform vec3 color;
      uniform float opacity; // Added opacity uniform
      varying vec2 vUv;
      
      void main() {
        // Create a radial gradient for blur effect
        float dist = distance(vUv, vec2(0.5));
        float alpha = (1.0 - smoothstep(0.4, 0.5, dist)) * opacity; // Multiply by opacity
        gl_FragColor = vec4(color, alpha);
      }
    `,
    transparent: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false,
  });
}

/**
 * Creates an enhanced blur shader material for trails with motion blur.
 */
export function createEnhancedBlurMaterial(color = 0xeeeeee) {
  return new THREE.ShaderMaterial({
    uniforms: {
      color: { value: new THREE.Color(color) },
      time: { value: 0.0 },
      opacity: { value: 0.8 }, // Added opacity uniform
    },
    vertexShader: `
        uniform float time;
        varying vec2 vUv;
        
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
    fragmentShader: `
        uniform vec3 color;
        uniform float opacity;
        varying vec2 vUv;
        
        void main() {
          // Create a radial gradient with some motion blur effect
          float dist = distance(vUv, vec2(0.5));
          float alpha = (1.0 - smoothstep(0.3, 0.6, dist)) * opacity;
          gl_FragColor = vec4(color, alpha);
        }
      `,
    transparent: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false,
  });
}
export function createPulsingBubblyGlowMaterial() {
  const material = new THREE.ShaderMaterial({
    uniforms: {
      time: { value: 0 },
      baseColor: { value: new THREE.Color(0xff69b4) }, // Pinkish color
      glowIntensity: { value: 50.0 },
      emissiveColor: { value: new THREE.Color(0xff69b4) }, // Glow color
    },
    vertexShader: `
            uniform float time;
            varying vec3 vPosition;
            void main() {
                // Create a pulsing, bubbly effect by modifying the vertex position
                float scale = sin(time * 2.0) * 0.1 + 1.0;
                vec3 pos = position * scale;
                vPosition = pos;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
            }
        `,
    fragmentShader: `
            uniform vec3 baseColor;
            uniform vec3 emissiveColor;
            uniform float time;
            varying vec3 vPosition;
            void main() {
                // Create a pulsing glow effect
                float glow = sin(time * 2.0) * 0.5 + 0.5;
                
                // Simulate emissive light by adding brightness
                vec3 color = baseColor * (0.5 + glow) + emissiveColor * glow * 0.5;
                
                // Output the final color
                gl_FragColor = vec4(color, 1.0);
            }
        `,
    transparent: true,
    blending: THREE.AdditiveBlending, // Ensure it glows nicely
    depthWrite: false, // Don't affect depth to make the glow smooth
  });

  // Animate the uniform time to make it pulsing
  const animate = () => {
    requestAnimationFrame(animate);
    material.uniforms.time.value += 0.02; // Adjust pulsing speed
  };
  animate();

  return material;
}

export function createWaveTrailMaterial(
  color = 0xe6a1c7,
  useTexture = false,
  texturePath = ""
) {
  const uniforms = {
    u_time: { value: 0.0 }, // Time uniform for animation
    color: { value: new THREE.Color(color) },
    opacity: { value: 0.5 }, // Base opacity
  };

  let texture;
  if (useTexture && texturePath) {
    const textureLoader = new THREE.TextureLoader();
    texture = textureLoader.load(texturePath);
    uniforms.texture = { value: texture };
  }

  return new THREE.ShaderMaterial({
    uniforms: uniforms,
    vertexShader: `
      uniform float u_time;
      uniform float opacity;
      varying vec2 vUv;
      varying float vOpacity;
      
      void main() {
        vUv = uv;
        
        // Apply sine wave deformation on the y-axis
        vec4 modelPosition = modelMatrix * vec4(position, 1.0);
        modelPosition.y += sin(modelPosition.x * 4.0 + u_time * 2.0) * 0.2;
        
        // Optional: Uncomment for more complex wave on z-axis
        // modelPosition.z += sin(modelPosition.z * 6.0 + u_time * 2.0) * 0.1;
        
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectedPosition = projectionMatrix * viewPosition;
        
        gl_Position = projectedPosition;
        
        // Pass varying opacity based on vertex position for fading effect
        // Adjust the denominator based on your scene scale and desired fading
        vOpacity = opacity * (1.0 - (modelPosition.y / 5.0));
      }
    `,
    fragmentShader: `
      uniform vec3 color;
      uniform float opacity;
      ${useTexture ? "uniform sampler2D texture;" : ""}
      varying vec2 vUv;
      varying float vOpacity;
      
      void main() {
        ${
          useTexture
            ? `
          // If using a texture, sample it
          vec4 texColor = texture2D(texture, gl_PointCoord);
          float alpha = texColor.a * opacity * vOpacity;
        `
            : `
          // Without texture: create a soft circular point
          float dist = distance(gl_PointCoord, vec2(0.5));
          if (dist > 0.5) discard; // Discard fragments outside the circle
          float alpha = (1.0 - dist) * opacity * vOpacity;
        `
        }
        
        gl_FragColor = vec4(color, alpha);
      }
    `,
    transparent: true,
    blending: THREE.AdditiveBlending, // For glowing effect
    depthWrite: false,
  });
}
