<template>
  <div class="logo-marquee">
    <p class="smallText nomargintopbottom orgs">
      Organisations we've worked with
    </p>
    <div class="marquee">
      <div class="marquee__inner">
        <!-- Render the logos array twice for seamless looping -->
        <img
          v-for="(logo, index) in partnerLogos.concat(partnerLogos)"
          :key="index"
          :src="logo"
          alt="Logo"
          class="partner-logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import config from "@/config.js"; // Import your config with the partners

export default {
  name: "LogoMarquee",
  setup() {
    // Extract logos from each partner in the config
    const partnerLogos = ref(
      config.partners.flatMap((partner) => partner.logos) // Flatten logos from all partners
    );

    return {
      partnerLogos,
    };
  },
};
</script>

<style scoped>
.logo-marquee {
  /* overflow: hidden; */
  width: 100vw;
  border-top: 1px solid var(--border-light);
  background-color: var(--bg-main-dark);
  padding: var(--largeSpace) 0;
  bottom: 0;
  transform: translate(calc(var(--space) * -1), 100%);
}

.marquee {
  display: flex;
  /* overflow: hidden; */
  position: relative;
  gap: var(--largeSpace);
}

.orgs {
  transform: translateY(-0.8em);
  opacity: 0.3;
  width: max-content;
  padding: var(--smallSpace) var(--space);
  background-color: var(--bg-main-dark);
}

.marquee__inner {
  display: flex;
  animation: marquee 55s linear infinite;
  gap: var(--doubleSpace);
}

.partner-logo {
  margin-right: var(--space);
  max-height: 45px;
  max-width: 128px;
  object-fit: contain;
  opacity: 0.3;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
</style>
