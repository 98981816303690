// src/store/index.js
import { createStore } from "vuex";

export default createStore({
  state: {
    clickedPhases: [],
    selectedServices: [], // Added to track selected services
  },
  mutations: {
    addPhase(state, phase) {
      state.clickedPhases.push(phase);
    },
    removePhase(state, phase) {
      const index = state.clickedPhases.indexOf(phase);
      if (index > -1) {
        state.clickedPhases.splice(index, 1);
      }
    },
    clearPhases(state) {
      state.clickedPhases = [];
    },
    toggleService(state, service) {
      const index = state.selectedServices.indexOf(service);
      if (index > -1) {
        state.selectedServices.splice(index, 1);
      } else {
        state.selectedServices.push(service);
      }
    },
    clearServices(state) {
      state.selectedServices = [];
    },
  },
  actions: {},
  modules: {},
});
