<!-- src/components/ContactCTA.vue -->
<template>
  <section class="contact-cta">
    <h1>{{ contactInfo.message }}</h1>
    <form @submit.prevent="handleSubmit" id="contact-form">
      <input
        type="text"
        placeholder="Your Name"
        v-model="formData.name"
        required
      />
      <input
        type="email"
        placeholder="Your Email"
        v-model="formData.email"
        required
      />
      <textarea
        placeholder="Your Message"
        v-model="formData.message"
        required
      ></textarea>
      <button type="submit">Send Message</button>
    </form>
    <!-- Logo Marquee -->
    <LogoMarquee class="bottom" />
  </section>
</template>

<script>
import { reactive, computed, watch } from "vue";
import { useStore } from "vuex";
import LogoMarquee from "@/components/LogoMarquee.vue";

export default {
  name: "ContactCTA",
  components: {
    LogoMarquee,
  },
  props: {
    contactInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const formData = reactive({
      name: "",
      email: "",
      message: "",
    });

    const selectedPhases = computed(() => store.state.clickedPhases);
    const selectedServices = computed(() => store.state.selectedServices);

    const prefillMessage = computed(() => {
      let message = "Hello Patio,\n\n";

      if (selectedServices.value.length > 0) {
        if (
          selectedServices.value.includes("product") &&
          selectedServices.value.includes("service")
        ) {
          message +=
            "My name is...\n\nI'm working on a product and a service.\n\n";
        } else if (selectedServices.value.includes("product")) {
          message += "My name is...\n\nI'm working on a product...\n\n";
        } else if (selectedServices.value.includes("service")) {
          message += "My name is...\n\nI am working on a service...\n\n";
        }
      } else {
        message += "My name is...\n\nI am working on...\n\n";
      }

      if (selectedPhases.value.length > 0) {
        message += `Possible phases involved: ${selectedPhases.value.join(
          ", "
        )}\n\nKind regards,\n`;
      }
      return message;
    });

    watch(
      () => [selectedServices.value.slice(), selectedPhases.value.slice()],
      () => {
        formData.message = prefillMessage.value;
      },
      { immediate: true }
    );

    const handleSubmit = () => {
      // Handle form submission logic here
      console.log("Form submitted:", formData);
      alert("Your message has been sent!");
      // Reset form
      formData.name = "";
      formData.email = "";
      formData.message = "";
      // Clear selections
      store.commit("clearPhases");
      store.commit("clearServices");
    };

    return {
      contactInfo: props.contactInfo,
      formData,
      handleSubmit,
    };
  },
};
</script>
<style scoped>
.contact-cta {
  height: 100%;
  scroll-snap-align: start;
  overflow: hidden;
  position: relative;
  padding: var(--space);
}

.contact-cta h1 {
  padding: var(--space) 0;
}

.contact-cta form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-cta input,
.contact-cta textarea {
  width: 100%;
  max-width: 500px;
  margin-bottom: var(--space);
  padding: 0 var(--largeSpace);
  height: var(--clickableHeight);
  font-size: var(--regularFontSize);
  border-radius: var(--border-radius-large);
  border: var(--button-border-default);
  background: var(--bg-main-dark);
  color: var(--text-white);
  transition: all var(--fastSpeed);
  box-sizing: border-box;
}

.contact-cta textarea {
  height: 250px;
  padding-top: var(--largeSpace);
}

.contact-cta input:focus,
.contact-cta textarea:focus {
  outline: none;
  border: var(--button-border-hover);
}

.contact-cta button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--largeSpace);
  height: var(--clickableHeight);
  font-size: var(--largeFontSize);
  border-radius: var(--border-radius-pill);
  border: var(--button-border-default);
  background: var(--bg-main-dark);
  color: var(--text-white);
  transition: all var(--fastSpeed);
  cursor: pointer;
  z-index: 1;
  margin-top: var(--space);
}

.contact-cta button:hover {
  border: var(--button-border-hover);
}

.contact-cta button:active {
  box-shadow: var(--button-shadow-inset);
  border: var(--button-border-active);
}
</style>
