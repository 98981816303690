<template>
  <canvas ref="threeCanvas" class="three-canvas"></canvas>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
  createBlurMaterial,
  createEnhancedBlurMaterial,
} from "../utils/shaderMaterials";

export default {
  name: "ThreeBackground",
  setup() {
    const threeCanvas = ref(null);
    let scene, camera, renderer, animationId;
    let controls;
    let phoneModel = null;
    let phonePivot = new THREE.Object3D(); // Create a pivot point
    let objects = []; // Orbiting objects
    let cardModel = null; // Metal card model
    let phoneBoundingSphereRadius = 0; // Store the bounding sphere radius
    let isVisible = false; // Track visibility state
    let observer = null; // Intersection observer

    const startRendering = () => {
      if (!isVisible) return;
      animate(); // Start the animation
    };

    const stopRendering = () => {
      cancelAnimationFrame(animationId); // Stop the animation
    };

    const observeVisibility = () => {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              isVisible = true;
              startRendering();
            } else {
              isVisible = false;
              stopRendering();
            }
          });
        },
        { threshold: 0.1 } // Trigger when 10% of the canvas is visible
      );
      if (threeCanvas.value) observer.observe(threeCanvas.value);
    };

    onMounted(() => {
      // Initialize Scene
      scene = new THREE.Scene();

      // Add fog to the scene for distant fading
      scene.fog = new THREE.Fog(0x000000, 15, 40); // Start and end distances

      // Initialize Camera
      const fov = 75;
      const aspect =
        threeCanvas.value.clientWidth / threeCanvas.value.clientHeight;
      const near = 0.1;
      const far = 1000;
      camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      camera.position.set(0, 2, 5);

      // Initialize Renderer
      renderer = new THREE.WebGLRenderer({
        canvas: threeCanvas.value,
        alpha: true,
        antialias: true,
      });
      renderer.setSize(
        threeCanvas.value.clientWidth,
        threeCanvas.value.clientHeight
      );
      renderer.setPixelRatio(window.devicePixelRatio || 1);

      // Add Lighting
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.4);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      directionalLight.position.set(10, 10, 10);
      scene.add(directionalLight);

      // Add HemisphereLight for soft overall lighting
      const hemisphereLight = new THREE.HemisphereLight(
        0xffffff,
        0x444444,
        0.6
      );
      hemisphereLight.position.set(0, 50, 0); // Above the scene
      scene.add(hemisphereLight);

      // Initialize OrbitControls
      controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      controls.enableZoom = false;
      controls.enablePan = false;
      controls.autoRotate = false;

      // Add the pivot to the scene
      scene.add(phonePivot);

      // Load the iPhone GLTF Model
      loadGLTFModel();

      // Add Orbiting Objects
      addOrbitingObjects();

      // Add Metal Card
      loadMetalCard();

      // Handle Window Resize
      window.addEventListener("resize", onWindowResize);

      // Observe component visibility
      observeVisibility();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", onWindowResize);
      cancelAnimationFrame(animationId);
      renderer.dispose();
      controls.dispose();
      if (observer && threeCanvas.value) observer.unobserve(threeCanvas.value);
    });

    const onWindowResize = () => {
      if (threeCanvas.value) {
        const width = threeCanvas.value.clientWidth;
        const height = threeCanvas.value.clientHeight;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
      }
    };

    const loadGLTFModel = () => {
      const loader = new GLTFLoader();
      loader.load(
        "/models/iphone_16/scene.gltf",
        (gltf) => {
          phoneModel = gltf.scene;

          // Scale and center the iPhone model
          phoneModel.scale.set(16, 16, 16);

          const box = new THREE.Box3().setFromObject(phoneModel);
          const center = box.getCenter(new THREE.Vector3());
          phoneModel.position.sub(center); // Center the model

          // Calculate bounding sphere radius with buffer
          phoneBoundingSphereRadius =
            box.getSize(new THREE.Vector3()).length() / 2 + 3;

          // Position the model slightly higher
          phoneModel.position.y += 0.5;

          phonePivot.add(phoneModel);
        },
        undefined,
        (error) => {
          console.error("Error loading GLTF model:", error);
        }
      );
    };

    const addOrbitingObjects = () => {
      const minOrbitRadius = phoneBoundingSphereRadius;

      const createOrbitingObject = (
        geometry,
        material,
        radiusOffset,
        yOffset,
        tiltAxis,
        tiltAngle,
        spinSpeed
      ) => {
        const object = new THREE.Mesh(geometry, material);
        object.userData.orbit = {
          radius: minOrbitRadius + radiusOffset,
          speed: 0.002 + Math.random() * 0.004,
          angle: Math.random() * Math.PI * 2,
          yOffset: yOffset,
          tiltAxis: tiltAxis,
          tiltAngle: tiltAngle,
          spinSpeed: spinSpeed,
        };
        scene.add(object);
        objects.push(object);
      };

      // Smaller Sphere (Aligned plane)
      createOrbitingObject(
        new THREE.SphereGeometry(0.3, 32, 32),
        createEnhancedBlurMaterial(),
        2,
        0.5,
        null,
        0,
        0.01
      );

      // Tilted Pyramid
      createOrbitingObject(
        new THREE.ConeGeometry(0.5, 0.68, 3),
        createEnhancedBlurMaterial(),
        4,
        1.0,
        new THREE.Vector3(1, 0, 0), // Tilt on x-axis
        Math.PI / 8,
        0.02
      );

      // Closer and Tilted Torus
      createOrbitingObject(
        new THREE.TorusGeometry(0.6, 0.05, 16, 100),
        createEnhancedBlurMaterial(),
        3.5,
        -0.5,
        new THREE.Vector3(0, 1, 0), // Tilt on y-axis
        Math.PI / 6,
        0.015
      );

      // Box (Aligned plane)
      createOrbitingObject(
        new THREE.BoxGeometry(0.45, 0.45, 0.45),
        createBlurMaterial(),
        8,
        -1.0,
        null,
        0,
        0.02
      );
    };

    const loadMetalCard = () => {
      const loader = new GLTFLoader();
      loader.load(
        "/models/metal_credit_card/scene.gltf",
        (gltf) => {
          cardModel = gltf.scene;

          // Scale down and position the card
          cardModel.scale.set(1, 1, 1); // Smaller size
          cardModel.position.set(0, 15, -8); // Start further back and higher
          scene.add(cardModel);

          cardModel.userData.fadeDistance = {
            start: 10,
            end: 25,
          }; // Set fade distances
        },
        undefined,
        (error) => {
          console.error("Error loading Metal Card model:", error);
        }
      );
    };

    const animate = () => {
      if (!isVisible) return;
      animationId = requestAnimationFrame(animate);

      phonePivot.rotation.y += 0.01;

      // Update positions of orbiting objects
      objects.forEach((obj) => {
        const orbit = obj.userData.orbit;
        orbit.angle += orbit.speed;

        obj.position.x = orbit.radius * Math.cos(orbit.angle);
        obj.position.z = orbit.radius * Math.sin(orbit.angle);
        obj.position.y = orbit.yOffset;

        if (orbit.tiltAxis) {
          obj.quaternion.setFromAxisAngle(orbit.tiltAxis, orbit.tiltAngle);
        }
        obj.rotation.y += orbit.spinSpeed;
      });

      // Update position and scale of the metal card
      if (cardModel) {
        cardModel.position.y -= 0.04; // Slower fall for better effect
        cardModel.rotation.x += 0.05; // Spin effect

        // Calculate scale factor based on the card's distance
        const fadeDistance = cardModel.userData.fadeDistance;
        const distance = Math.abs(cardModel.position.y);
        if (distance > fadeDistance.start) {
          const scaleFactor = Math.max(
            0,
            1 -
              (distance - fadeDistance.start) /
                (fadeDistance.end - fadeDistance.start)
          );
          cardModel.scale.set(scaleFactor, scaleFactor, scaleFactor); // Scale the card down
        } else {
          cardModel.scale.set(1, 1, 1); // Reset to normal scale when within range
        }

        // Reset the card's position when it scales out of view
        if (distance > fadeDistance.end) {
          cardModel.position.y = 15 + Math.random() * 5; // Reappear higher
          cardModel.position.x = Math.random() * 6 - 3; // Randomize x position
          cardModel.position.z = Math.random() * 4 - 2 - 8; // Randomize z position
          cardModel.scale.set(1, 1, 1); // Reset scale
        }
      }

      controls.update();
      renderer.render(scene, camera);
    };

    return {
      threeCanvas,
    };
  },
};
</script>

<style scoped>
.three-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}
</style>
