import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// Import main.css
import "./assets/main.scss";

createApp(App).use(store).use(router).mount("#app");
