<template>
  <div class="media-carousel">
    <!-- Scrollable Media Container -->
    <div class="media-container" ref="mediaContainer">
      <!-- Media Items -->
      <div
        v-for="(item, index) in mediaItems"
        :key="'media-' + index"
        class="media-item"
        @click="handleClick($event)"
      >
        <template v-if="item.type === 'image'">
          <img :src="item.src" alt="Work Image" class="media-image" />
        </template>
        <template v-else-if="item.type === 'youtube' || item.type === 'vimeo'">
          <iframe
            :src="item.src"
            frameborder="0"
            allowfullscreen
            class="media-video"
          ></iframe>
        </template>
        <template v-else-if="item.type === 'video'">
          <video controls :src="item.src" class="media-video"></video>
        </template>
      </div>
    </div>

    <!-- Navigation Arrows (Removed) -->
    <!-- The arrow buttons have been removed -->
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";

export default {
  name: "MediaCarousel",
  props: {
    workImages: {
      type: Array,
      default: () => [],
    },
    videoLinks: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const mediaContainer = ref(null);
    const currentIndex = ref(0);

    const mediaItems = computed(() => {
      const items = [];

      // Add images
      if (props.workImages && props.workImages.length > 0) {
        props.workImages.forEach((image) => {
          items.push({ type: "image", src: image });
        });
      }

      // Add videos
      if (props.videoLinks && props.videoLinks.length > 0) {
        props.videoLinks.forEach((videoLink) => {
          let videoType = "";
          let embedUrl = "";

          if (
            videoLink.includes("youtube.com") ||
            videoLink.includes("youtu.be")
          ) {
            videoType = "youtube";
            let videoId = "";
            if (videoLink.includes("youtube.com")) {
              const urlParams = new URLSearchParams(videoLink.split("?")[1]);
              videoId = urlParams.get("v");
            } else if (videoLink.includes("youtu.be")) {
              videoId = videoLink.split("/").pop();
            }
            embedUrl = `https://www.youtube.com/embed/${videoId}`;
          } else if (videoLink.includes("vimeo.com")) {
            videoType = "vimeo";
            const videoId = videoLink.split("/").pop();
            embedUrl = `https://player.vimeo.com/video/${videoId}`;
          } else {
            videoType = "video";
            embedUrl = videoLink;
          }

          items.push({ type: videoType, src: embedUrl });
        });
      }

      return items;
    });

    const scrollToCurrentIndex = () => {
      if (mediaContainer.value) {
        const item = mediaContainer.value.children[currentIndex.value];
        if (item) {
          const scrollPosition = item.offsetLeft;
          mediaContainer.value.scrollTo({
            left: scrollPosition,
            behavior: "smooth",
          });
        }
      }
    };

    const scrollNext = () => {
      currentIndex.value = (currentIndex.value + 1) % mediaItems.value.length;
      scrollToCurrentIndex();
    };

    const scrollPrev = () => {
      currentIndex.value =
        (currentIndex.value - 1 + mediaItems.value.length) %
        mediaItems.value.length;
      scrollToCurrentIndex();
    };

    const handleClick = (event) => {
      // Determine where the user clicked within the media item
      const clickX = event.clientX;
      const elementWidth = event.currentTarget.clientWidth;
      const elementLeft = event.currentTarget.getBoundingClientRect().left;

      const relativeX = clickX - elementLeft;

      if (relativeX < elementWidth / 2) {
        // Clicked on the left half
        scrollPrev();
      } else {
        // Clicked on the right half
        scrollNext();
      }
    };

    const handleWheel = (e) => {
      if (window.innerWidth < 768) {
        e.preventDefault(); // Only prevent default on smaller screens
        if (e.deltaY < 0) {
          // Scrolling up
          scrollPrev();
        } else if (e.deltaY > 0) {
          // Scrolling down
          scrollNext();
        }
      }
    };

    const handleScroll = () => {
      if (mediaContainer.value) {
        const scrollLeft = mediaContainer.value.scrollLeft;
        const items = mediaContainer.value.children;
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (
            item.offsetLeft <=
              scrollLeft + mediaContainer.value.offsetWidth / 2 &&
            item.offsetLeft + item.offsetWidth >
              scrollLeft + mediaContainer.value.offsetWidth / 2
          ) {
            currentIndex.value = i;
            break;
          }
        }
      }
    };

    onMounted(() => {
      if (mediaContainer.value) {
        mediaContainer.value.addEventListener("wheel", handleWheel, {
          passive: false,
        });
        mediaContainer.value.addEventListener("scroll", handleScroll);
      }
    });

    onBeforeUnmount(() => {
      if (mediaContainer.value) {
        mediaContainer.value.removeEventListener("wheel", handleWheel);
        mediaContainer.value.removeEventListener("scroll", handleScroll);
      }
    });

    return {
      mediaContainer,
      mediaItems,
      scrollNext,
      scrollPrev,
      handleClick, // Expose the handleClick method
    };
  },
};
</script>

<style scoped>
.media-carousel {
  position: relative;
  width: 100%;
  max-width: 1024px;
  overflow: hidden; /* Hide any overflow */
}

.media-container {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  border-radius: var(--border-radius-pill);
  margin: 0 var(--space);
}

.media-container.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.media-container::-webkit-scrollbar {
  display: none;
}

.media-item {
  flex: none;
  scroll-snap-align: start;
  width: 100%; /* Ensure items fill the container */
  max-height: 40vh;
  height: auto;
  position: relative;
}

.media-image,
.media-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2;
  background-color: var(--bg-light-white);
  width: 48px;
}

.carousel-button.left {
  left: var(--doubleSpace);
}

.carousel-button.right {
  right: var(--doubleSpace);
}

.icon {
  width: 24px;
  height: 24px;
}

.iconChild {
  mix-blend-mode: difference;
}

.rotate-left {
  transform: rotate(-90deg);
}

.rotate-right {
  transform: rotate(90deg);
}

@media (max-width: 600px) {
  .media-item {
    max-width: 100%;
    max-height: 42vh;
    height: auto;
  }
  .media-container {
    border-radius: 0;
    margin: 0 0;
  }
}

/* Add this media query */
@media (min-width: 768px) {
  .media-container {
    overflow-x: hidden; /* Disable horizontal scrolling on desktop */
  }
}
</style>
