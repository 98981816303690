<!-- src/components/LandingPage.vue -->
<template>
  <section class="landing-page section relative">
    <!-- Three.js Background -->
    <ThreeBackground />

    <!-- Centered Logo -->
    <div class="logoAndSignature column">
      <h1 id="logo">patio</h1>
      <h2 class="regularWeight signature">
        design from research to implementation
      </h2>
    </div>

    <div class="content regularWeight column doubleGap">
      <div class="padded">
        <p class="divMarginBottom">
          We're an agency specialised in designing services end-to-end.
        </p>
        <div class="buttonContainer row gap">
          <button
            :class="{ active: selectedServices.includes('product') }"
            @click="toggleService('product')"
          >
            I'm working on a product
          </button>
          <button
            :class="{ active: selectedServices.includes('service') }"
            @click="toggleService('service')"
          >
            I'm working on a service
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ThreeBackground from "./ThreeBackground.vue";
import LogoMarquee from "./LogoMarquee.vue"; // Import the LogoMarquee component

import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "LandingPage",
  components: {
    ThreeBackground,
    LogoMarquee,
  },
  setup() {
    const store = useStore();

    const toggleService = (service) => {
      store.commit("toggleService", service);
    };

    const selectedServices = computed(() => store.state.selectedServices);

    return {
      toggleService,
      selectedServices,
    };
  },
};
</script>

<style scoped>
.landing-page {
  box-sizing: border-box;
  height: 100%;
  scroll-snap-align: start;
  position: relative;
}

.logoAndSignature {
  padding: 0 var(--doubleSpace);
}

.content {
  /* bottom: var(--smallClickableHeight); */
  bottom: 0;
  position: absolute;
  background-color: var(--bg-main-dark);
  z-index: 9;
  border-top: 1px solid var(--border-light);
  width: 100%;
}

#logo {
  font-family: "Pencerio-Hairline", system-ui, serif;
  font-size: 8rem;
  color: var(--text-white);
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.signature {
  font-size: var(--largerFontSize);
  color: var(--text-white);
  bottom: var(--doubleSpace);
}

button.active {
  background-color: var(--light-pink);
  color: var(--bg-main-dark);
}

button.primaryButton.active {
  background-color: var(--bg-light-white);
  color: var(--bg-main-dark);
}

.bottom {
  bottom: 0;
}

.padded {
  padding: var(--doubleSpace) var(--space);
}

/* Responsive Font Size (Optional) */
@media (max-width: 768px) {
  #logo {
    font-size: 7.5rem;
  }
  .content {
    bottom: var(--smallClickableHeight) !important;
  }
}

@media (max-width: 480px) {
  #logo {
    font-size: 5rem;
  }
}
</style>
