<template>
  <div class="navigation-arrows">
    <!-- Up arrow with transition -->
    <transition name="fade-slide-top">
      <div
        v-if="canNavigateUp"
        class="up-arrow navigation-arrow"
        @click="navigateUp"
        tabindex="0"
        @keypress.enter="navigateUp"
        aria-label="Scroll to previous section"
        role="button"
      >
        <svg class="icon">
          <use href="./../assets/iconset.svg#arrowup"></use>
        </svg>
      </div>
    </transition>

    <!-- Down arrow with transition -->
    <transition name="fade-slide-bottom">
      <div
        v-if="canNavigateDown"
        :class="[
          'down-arrow',
          'navigation-arrow',
          { 'on-design-thinking': isOnDesignThinking },
          { 'on-partners-section': isOnPartnersSection },
        ]"
        @click="navigateDown"
        tabindex="0"
        @keypress.enter="navigateDown"
        aria-label="Scroll to next section"
        role="button"
      >
        <div class="down-arrow-content">
          <!-- Display appropriate text based on the current section -->
          <span v-if="downArrowText" class="down-arrow-text">
            {{ downArrowText }}
          </span>
          <svg class="icon">
            <use href="./../assets/iconset.svg#arrowdown"></use>
          </svg>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";

export default {
  name: "NavigationArrows",
  setup() {
    const sections = ref([]);
    const currentSectionIndex = ref(0);
    const canNavigateUp = ref(false);
    const canNavigateDown = ref(false);
    let designThinkingIndex = -1;
    let partnersIndex = -1;

    let observer = null;

    /**
     * Updates the navigation availability based on the current section index.
     */
    const updateNavigation = () => {
      canNavigateUp.value = currentSectionIndex.value > 0;
      canNavigateDown.value =
        currentSectionIndex.value < sections.value.length - 1;
    };

    /**
     * Navigates to the previous section.
     */
    const navigateUp = () => {
      if (canNavigateUp.value) {
        const prevSection = sections.value[currentSectionIndex.value - 1];
        if (prevSection) {
          prevSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    /**
     * Navigates to the next section.
     */
    const navigateDown = () => {
      if (canNavigateDown.value) {
        const nextSection = sections.value[currentSectionIndex.value + 1];
        if (nextSection) {
          nextSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    /**
     * Handles intersection changes to update the current section index.
     * @param {IntersectionObserverEntry[]} entries
     */
    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sections.value.indexOf(entry.target);
          if (index !== -1) {
            currentSectionIndex.value = index;
            updateNavigation();
          }
        }
      });
    };

    onMounted(() => {
      // Select all sections with the class 'section'
      sections.value = Array.from(document.querySelectorAll(".section"));

      if (sections.value.length === 0) {
        console.warn(
          "NavigationArrows.vue: No elements with class 'section' found."
        );
      }

      // Find the index of the DesignThinking.vue section
      const designThinkingSection = document.getElementById(
        "design-thinking-section"
      );
      designThinkingIndex = sections.value.indexOf(designThinkingSection);

      // Find the index of the PartnersPage.vue section
      const partnersSection = document.getElementById("partners-section");
      partnersIndex = sections.value.indexOf(partnersSection);

      // Initialize the IntersectionObserver
      observer = new IntersectionObserver(handleIntersect, {
        root: null,
        threshold: 0.1, // Trigger when 10% of the section is visible
      });

      // Observe each section
      sections.value.forEach((section) => {
        observer.observe(section);
      });

      // Initial update to set navigation availability
      updateNavigation();
    });

    onBeforeUnmount(() => {
      if (observer) {
        observer.disconnect();
      }
    });

    /**
     * Computed property to check if we're on DesignThinking.vue
     */
    const isOnDesignThinking = computed(() => {
      return currentSectionIndex.value === designThinkingIndex;
    });

    /**
     * Computed property to check if we're on PartnersPage.vue
     */
    const isOnPartnersSection = computed(() => {
      return currentSectionIndex.value === partnersIndex;
    });

    /**
     * Computed property for the down arrow text
     */
    const downArrowText = computed(() => {
      if (isOnDesignThinking.value) {
        return "View Work";
      } else if (isOnPartnersSection.value) {
        return "Get in touch";
      } else {
        return "";
      }
    });

    return {
      canNavigateUp,
      canNavigateDown,
      navigateUp,
      navigateDown,
      downArrowText,
      isOnDesignThinking,
      isOnPartnersSection,
    };
  },
};
</script>

<style scoped>
.navigation-arrows {
  position: fixed;
  top: 0;
  right: var(--space);
  width: var(--smallClickableHeight);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: none;
  z-index: 1000;
  align-items: flex-end;
}

.down-arrow-content {
  display: flex;
  align-items: center;
}

.down-arrow-text {
  margin-right: var(--smallSpace);
  white-space: nowrap;
  font-size: var(--largeFontSize);
  color: var(--text-dark);
}

/* Adjust styles when on specific components */
.on-design-thinking,
.on-partners-section {
  padding: var(--space);
  width: fit-content;
}

/* Up Arrow Animations */
.fade-slide-top-enter-active,
.fade-slide-top-leave-active {
  transition: opacity var(--fastSpeed) ease, transform var(--fastSpeed) ease,
    filter var(--fastSpeed) ease;
}

.fade-slide-top-enter-from,
.fade-slide-top-leave-to {
  opacity: 0;
  transform: translateY(-30px);
  filter: blur(5px);
}

.fade-slide-top-enter-to,
.fade-slide-top-leave-from {
  opacity: 1;
  transform: translateY(0);
  filter: blur(0px);
}

/* Down Arrow Animations */
.fade-slide-bottom-enter-active,
.fade-slide-bottom-leave-active {
  transition: opacity var(--fastSpeed) ease, transform var(--fastSpeed) ease,
    filter var(--fastSpeed) ease;
}

.fade-slide-bottom-enter-from,
.fade-slide-bottom-leave-to {
  opacity: 0;
  transform: translateY(30px);
  filter: blur(5px);
}

.fade-slide-bottom-enter-to,
.fade-slide-bottom-leave-from {
  opacity: 0.8;
  transform: translateY(0);
  filter: blur(0px);
}

/* Optional: Style the SVG icons */
.icon {
  width: 24px;
  height: 24px;
  fill: none;
  stroke: currentColor;
  stroke-width: 2;
}
</style>
